import React from "react"
import { Link } from "gatsby"

import Hero from "../components/hero" 
import Layout from "../components/layout"
import MaxWidth from "../components/maxWidth"
import SEO from "../components/seo"

const ForBusinessPage = () => (
	 <>
	  <SEO title="Employer Help" keywords={[`Employer EAP Services`, `EAP`, `Employee Assistence`, `Boston area EAP`, `Corporate EAP`]} description="Benefits for employers who use an employee assistance program."/>
	  <Hero>
	  	<h1>Guiding Businesses to Help Employees</h1>
	  </Hero>
	  <Layout>
	  <MaxWidth>
		<h2>Benefits of an EAP</h2>

		<p>By building trusted relationships with our clients, we work to provide the resources that can help employees through a difficult time. These resources cover topics that range from day-to-day occurrences, to events that can be rare or life-altering for individuals.</p>
		
		<h4 style={{marginBottom: '5px'}}>Benefits of an EAP Include</h4>
			<ul>
				<li>Improved work performance</li>
				<li>Reduced turnover</li>
				<li>Improved workplace relationships</li>
				<li>Reduced absenteeism</li>
			</ul>
		<a href='../corpeap-mgr-guide.pdf' target="_blank" className='btn secondary'>Download Guide for Managers &amp; Supervisors</a>
		<p>Throughout Massachusetts and New England, Corporate EAP Resources provides a network of offices available for EAP service. Our experienced staff of counselors and therapists are trained and skilled in the assessment and treatment of a wide range of issues that can affect performance in the workplace. An employee may call directly for assistance (self-referral) or may be referred by his/her supervisor or manager (management-referral).</p>

		<p><Link to='/about-referrals' className='btn secondary' style={{marginLeft: '0px'}}>Learn More About the Referral Process</Link></p>
		<Link to='/contact-us' className='btn primary'>Contact Us</Link>
	  </MaxWidth>
	 </Layout>
	 </>
)

export default ForBusinessPage